.language-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    font-size: 1rem;
    /*color: #fff;*/
    color: var(--color-anti-level-0);
    padding: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 4px;
    margin: 10px;
    cursor: pointer;
    font-family: var(--main-font);
}

.language-selector:hover {
    background: var(--color-active-selection-2);
    font-family: var(--main-font);
    color: var(--color-level-0);
}

.language-selector > IMG {
    height: 18px;
    margin-right: 0.5rem;
    border-radius: 3px;
}

.language-selector > DIV {}


.language-list-backpan {
    display: block;
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100vh;
    width: 100vw;
    min-height: 100vh;
    min-width: 100vw;
    backdrop-filter: blur(3px);
    background: var(--small-opacity-theme-color);
    z-index: 99;
}

.language-list-container {
    z-index: 100;
    /*background: #fffc;*/
    background: var(--big-opacity-theme-color);
    border-radius: 10px;
    min-width: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(280px - 4rem);
    margin: 10rem auto;
    align-content: center;
    align-items: center;
    backdrop-filter: blur(8px);
    /*border: solid 1px #fff;*/
    /*padding-left: 2rem;*/
    /*padding-right: 2rem;*/
}

.language-list-inwait-wheel {
    margin-top: 2rem;
    margin-bottom: 2rem;
}


.language-list-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-bottom: solid 1px #0001;
    font-size: 1.25rem;
    height: 35px;
    cursor: pointer;
}
.language-list-row:hover {
    background: #0a0;
}
.language-list-row:hover > DIV {
    color: #fff;
}

.language-list-row-last {
    border-bottom: solid 0px #0000;
}
.language-list-row-last:hover {
    background: #0a0;
    border-radius: 0px 0px 10px 10px;
}


.language-list-row-first {
    border-bottom: solid 0px #0000;
}
.language-list-row-first:hover {
    background: #0a0;
    border-radius: 10px 10px 0px 0px;
}




.language-list-row > IMG {
    height: 20px;
    align-self: center;
    margin-left: 1rem;
}

.language-list-row > DIV {
    align-self: center;
    margin-right: 1rem;
    color: var(--color-anti-level-2)
}




