@media
only screen
and (min-width: 40px)
and (max-width: 640px)
and (orientation: portrait)
{

	#slide_05
	{
		display: none;
	}

	.desktop {
		display: none !important;
	}

	#logo.mobile {
		display: inherit;
	}

	#menu {
		height: 40px;
	}

	#menu > IMG {
		height: 100% !important;
	}


	.menu-panel {

	}

	.menu-panel.popup-list-backpan-blurred {
		backdrop-filter: blur(6px);
	}

	.mobile-settings-panel {
		backdrop-filter: blur(20px);
		background: var(--middle-opacity-theme-color);
		padding: 1rem 2rem;
		border-radius: 16px;
		overflow: hidden;
		max-width: 640px;
		width: calc(75% - 4rem);
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-end;
		justify-items: center;
		margin: 20% auto;
	}


	#slide_02 > DIV,
	#slide_04 > DIV
	{
		width: calc(80% - 0rem) !important;
	}

}


@media
only screen
and (min-width: 640px)
{


	.mobile {
		display: none !important;
	}

}

