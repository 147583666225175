#slide_02 {
	margin-bottom: 20vh;
}

#slide_02 > DIV > SPAN {
	font-weight: 500;
	font-family: var(--page-font);
	font-size: var(--main-huge-font-size);
	text-align: center;
	padding-bottom: 1rem;
}

#slide_02 > DIV {
	font-weight: 400;
	font-family: var(--page-font);
	font-size: var(--main-font-size);
	backdrop-filter: blur( var(--different-blur-for-theme) );
	background: var(--middle-opacity-theme-color);
	padding: 1rem 2rem;
	margin: 4rem auto;
	border-radius: 16px;
	overflow: hidden;
	max-width: 780px;
	width: calc(75% - 4rem - 8rem);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	text-align: center;
	/*text-align: justify;*/
}
