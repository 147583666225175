.dark-mode-toggle-component {
	font-size: var(--main-medium-big-font-size);
	font-family: var(--main-font);
	min-width: 140px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	color: var(--color-anti-level-0);
}

.dark-mode-toggle-component > SPAN {
	margin-right: 0.5rem;
	align-self: center;
}

