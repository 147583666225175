#slide_03 {
	background-position-y: 100%;
	font-family: var(--page-font);
	color: var(--color-anti-level-0);
	/*min-width: 440px;*/
	/*width: 100%;*/
	max-width: 1024px;
	margin: auto;
	margin-bottom: 1rem;
	min-height: 640px;
}

#slide_03 > DIV {
	backdrop-filter: blur(2px);
	/*min-width: 440px;*/
	width: 100%;
	/*max-width: calc(1024px - 2rem);*/
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	justify-items: center;
	min-height: 400px;
}

#slide_03 > DIV > SPAN {
	font-weight: 500;
	font-family: var(--page-font);
	font-size: var(--main-huge-font-size);
	color: var(--color-secondary-main-2);
	text-align: center;
	padding-bottom: 4rem;
}

#slide_03 > DIV > DIV {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
}

#slide_03 > DIV > DIV > DIV {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	backdrop-filter: blur(20px);
	background: var(--big-opacity-theme-color);
	padding: 1rem;
	margin: 0.5rem;
	max-width: 250px;
	width: 200px;
	border-radius: 8px;
	color: var(--color-anti-level-0);
}

#slide_03 > DIV > DIV > DIV > SPAN {
	font-weight: 500;
	font-family: var(--page-font);
	font-size: var(--main-medium-big-font-size);
	color: var(--color-secondary-main-1);
}

#slide_03 > DIV > DIV > DIV > DIV {
	color: var(--color-anti-level-1);
}
