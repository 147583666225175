.waiting-over-component-backpan {
    /* background: #fff4; */
    /* backdrop-filter: blur(2px); */
    display: flex;
    flex-direction: column;
    /*justify-content: space-around;*/
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.waiting-over-component-backpan > DIV {
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    /*width: 25%;*/
    min-width: 150px;
    /*min-height: 200px;*/
    align-items: center;

    font-family: var(--secondary-font);
    font-size: var(--main-big-font-size);
    color: var(--color-level-0)
}

.waiting-over-component-backpan > DIV > SPAN {
    /*font-family: var(--secondary-font);*/
    /*font-size: var(--main-big-font-size);*/
}

/* sovrascritto e vince */
@keyframes blur-in {
    from {
      backdrop-filter: blur(0px);
      background: #fff0;
    }
    to {
      backdrop-filter: blur(4px);
      background: #0001;
    }
  }
