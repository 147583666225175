

#slide_01 {
	display: flex;
	flex-direction: row;
	/*justify-content: space-between;*/
	justify-content: center;
	flex-wrap: wrap-reverse;
	width: calc(100% - 8rem);
	max-width: 1300px;
	margin: 10vh auto;
	align-items: flex-end;
}

#slide_01 > DIV {
	width: calc(48% - 2rem);
	min-width: 440px;
	max-width: 840px;
	margin: 1rem;
}

#slide_01 > DIV.left {
	min-width: 320px;
	/*width: 100%;*/
	/*max-width: 840px;*/
	/*padding-right: 20px;*/
	/*text-align: justify;*/
}


#slide_01 > DIV.right {
	overflow: hidden;
	border-radius: 16px;
	height: 320px;
	/*min-width: 600px;*/
	/*max-width: 840px;*/
	/*margin-left: 2rem;*/
	/*width: 100%;*/
	background-size: 120% 120%;
	background-position-x: 100%;
	background-position-y: 0%;
	background-repeat: no-repeat;
	margin-bottom: 2rem;
}


#slide_01 > DIV.left > LABEL {
	font-size: var(--main-huge-font-size);
	color: var(--color-anti-level-1);
	font-family: var(--page-font);
	/*margin-bottom: 1rem;*/
	text-align: left !important;
	/*color: var(--color-evident-2) !important;*/
}

#slide_01 > DIV.left > SPAN {
	font-size: var(--main-medium-big-font-size);
	color: var(--color-anti-level-2);
	font-family: var(--page-font);
	margin-bottom: 1rem;
}


/* gradiente */
#slide_01 > DIV.right > DIV {
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: linear-gradient(to right, var(--theme-color), var(--big-opacity-theme-color), var(--middle-opacity-theme-color), var(--small-opacity-theme-color), transparent);
	overflow: hidden;
	border-radius: 16px;
	height: 100%;
}


#slide_01 > DIV.right > DIV > DIV {
	backdrop-filter: blur(20px);
	background: var(--middle-opacity-theme-color);
	padding: 2rem 2rem;
	border-radius: 16px;
	overflow: hidden;
	margin-left: 3.5rem;
	max-width: 640px;
	width: calc(70% - 4rem);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}


#slide_01 > DIV.right > DIV > DIV > H4 {
	font-weight: 500;
	font-family: var(--page-font);
	font-size: var(--main-huge-font-size);
	margin-bottom: 0rem;
	margin-top: 0.5rem;
}

#slide_01 > DIV.right > DIV > DIV > LABEL.smaller {
	font-size: var(--main-huge-font-size);
	margin-bottom: 0px;
}

#slide_01 > DIV.right > DIV > DIV > LABEL {
	/*font-size: var(--main-giga-font-size);*/
	/*color: var(--color-anti-level-1);*/
	/*font-family: var(--page-font);*/
	font-size: var(--main-giga-font-size);
	color: var(--color-anti-level-1);
	font-family: var(--page-font);
	text-align: center;
	line-height: 40px;
	margin-bottom: 1rem;

}

#slide_01 > DIV.right > DIV > DIV > SPAN {
	font-size: var(--main-medium-big-font-size);
	color: var(--color-anti-level-2);
	font-family: var(--page-font);
	margin-bottom: 1rem;
}

#slide_01 > DIV.right > DIV > DIV > DIV {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-top: 1rem;
}
