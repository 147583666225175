#target-device-tags-component {
	align-self: flex-start;
	/*margin-top: 1rem;*/
	/*position: relative;*/
	/*bottom: 0px;*/
}

.small-icon {
	height: 16px;
	background: var(--color-level-1);
	color: var(--color-anti-level-1);
	display: inline-flex;
	flex-direction: row;
	justify-content: space-between;
	/*width: 100px;*/
	align-items: center;
	padding: 0.25rem;
	font-family: var(--mono-font);
	font-size: var(--small-font-size);
	border-radius: 60px;
	padding-left: 0.5rem;
	padding-right: 0.75rem;
	margin-left: 0.25rem;
	margin-right: 0.25rem;
}



.small-icon > IMG,
.small-icon-svg
{
	height: 20px;
	margin-right: 10px;
}

.small-icon-svg {
	shape-rendering: geometricPrecision;
	text-rendering: geometricPrecision;
	image-rendering: optimizeQuality;
	fill-rule: evenodd;
	clip-rule: evenodd
}
