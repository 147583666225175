
/*
sfondo poco blur ma con colore
con il pannello molto blur ma con il colore
 */
 .popup-list-backpan-blurred {
    display: block;
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100vh;
    width: 100vw;
    min-height: 100vh;
    min-width: 100vw;
    backdrop-filter: blur(3px);
    background: var(--middle-opacity-inverse-theme-color);
    z-index: 99;
  }


.modal-container-blurred {

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    z-index: 100;
    border-radius: 10px;
    min-width: 120px;
    width: calc(280px - 4rem);
    margin: 6rem auto;
    align-content: center;
    align-items: center;
    backdrop-filter: blur(16px);
    background: var(--big-opacity-theme-color);
    /*min-width: 320px;*/
    max-width: 640px;
  }

  .modal-container-blurred.large {
    /*width: calc(640px - 4rem);*/
    width: calc(100% - (50% - 280px + 10rem));
  }


.modal-container-blurred > DIV {
    min-width: 200px;
}


.base-text-style {
    /*color: var(--color-anti-level-1);*/
    color: var(--sky-color);
    font-size: var(--main-font-size);
    font-family: var(--page-font);
}


INPUT.landing-input {
  /*background: var(--middle-opacity-inverse-theme-color);*/
  background: var(--middle-opacity-inverse-theme-color) url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="30"><text x="8" y="21" style="font-family: Verdana; font-size: 14px;" >+39</text></svg>') no-repeat;
    padding-left: 2.75rem;
    color: var(--sky-color);
    font-size: var(--main-medium-big-font-size);
    font-family: var(--page-font);
    width: 75%;
}



.role-icon-button,
.element-icon-button
{
  margin: 1rem;
  padding: 2rem;
  width: calc(40% - 6rem);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.role-icon-button:hover {
  background: #fff5;
  border-radius: 10px;
}

.role-icon-button:hover > IMG {
  transform: scale(1.1, 1.1);
  filter:
    /*saturate(100%)*/
          grayscale(0%)
;
}

.role-icon-button > IMG,
.element-icon-button > IMG
{
    width: 80%;
    /*margin-bottom: 1rem;*/
    filter:
        /*saturate(100%)*/
        grayscale(70%)
        hue-rotate(170deg)
    ;
}

.role-icon-button > SPAN,
.element-icon-button > SPAN
{
    text-align: center;
    font-size: 1rem;
    color: var(--color-anti-level-0);
}


.role-icon-button > SPAN.margin-top,
.element-icon-button > SPAN.margin-top
{
    margin-top: 1.5rem;
}
