#slide_04 {
	background-position-y: 10%;
	min-height: calc(520px - 6rem);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	flex-wrap: wrap;
	padding-top: 6rem;
}


#slide_04 > DIV {
	font-weight: 400;
	font-family: var(--page-font);
	font-size: var(--main-font-size);
	/*backdrop-filter: blur( var(--different-blur-for-theme) );*/
	backdrop-filter: blur( 20px );
	/*background: var(--middle-opacity-theme-color);*/
	padding: 1rem 2rem;
	margin: 1rem;
	border-radius: 16px;
	overflow: hidden;
	max-width: 780px;
	width: calc(25% - 0rem);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	text-align: center;
	/*text-align: justify;*/
	color: var(--color-secondary-main-1);
	/*color: var(--color-anti-level-0);*/
	min-height: 200px;
}


#slide_04 > DIV:hover {
	background: var(--middle-opacity-theme-color) !important;
}


#slide_04 > DIV > SPAN {
	font-weight: 500;
	font-size: var(--main-medium-big-font-size);
	font-family: var(--page-font);
	margin-bottom: 1rem;
}

#slide_04 > DIV > UL,
#slide_04 > DIV > EM
{
	text-align: left;
}
