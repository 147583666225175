.App {
	min-height: 100vh;
	background-color: var(--color-level-0);
	color: var(--color-anti-level-0);
	font-family: var(--page-font);
	display: flex;
	flex-direction: column;
	align-items: center;
	/*align-items: flex-start;*/
	/*align-items: stretch;*/
	justify-content: flex-start;
	font-size: var(--main-medium-big-font-size);
	/*position: static;*/
	width: 100%;
	overflow: hidden;
}

/*
#splash-screen {
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 100;
	background: var(--theme-color);
}

#splash-screen.vanish {
	z-index: -100;
	opacity: 0;
	transition: 1.5s;
}

#splash-screen > IMG {
	width: 100%;
	margin: -5% auto;
	display: block;
	aspect-ratio: 1;
	height: auto;
	max-width: 1230px;
}
*/

#header {
	color: var(--color-anti-level-2);
	min-width: calc(100vw - 0rem);
	height: var(--header-height);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	font-size: var(--main-huge-font-size);
	font-family: var(--main-font);
	position: fixed;
	z-index: 1;
	background: var(--color-level-0);
}

#header > DIV {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

#header > DIV > IMG {
	height: calc( var(--header-height) - 4px );
	margin-left: 1rem;
	margin-right: 0.5rem;
}





#page {
	width: 100%;
	margin-left: 1rem;
	margin-right: 1rem;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.slides {
	padding: 2rem;
	background-position: 50% 80%;
	background-size: cover;
	background-repeat: no-repeat;
	margin: 6rem 0px;
	min-height: 520px;
}




DIV.ballName {
	background: var(--celestino);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 120px;
	height: 120px;
	border-radius: 100px;
	align-self: flex-start;
	overflow: hidden;

	z-index: 1;

	/*position: relative;*/
	/*top: calc(1rem - 15vh);*/
	/*left: calc(50% - 60px);*/

	margin: 0px auto;

	-moz-animation: shadowPulse 2s linear infinite;
	-webkit-animation: shadowPulse 2s linear infinite;
	animation: shadowPulse 2s linear infinite;
}


DIV.ballName.scan {
	background: var(--color-evident-2);
	-moz-animation: shadowGreenPulse 2s linear infinite;
	-webkit-animation: shadowGreenPulse 2s linear infinite;
	animation: shadowGreenPulse 2s linear infinite;
}


DIV.ballName.management {
	/*background: var(--medium-red-color);*/
	background: transparent;
	-moz-animation: shadowRedPulse 2s linear infinite;
	-webkit-animation: shadowRedPulse 2s linear infinite;
	animation: shadowRedPulse 2s linear infinite;
}

DIV.ballName.management > IMG {
	width: 80px;
	margin: 10px auto;
}

DIV.ballName.management > LABEL {
	top: -20px;
}


DIV.ballName > IMG {}

DIV.ballName > LABEL {
	text-align: center;
	color: var(--color-anti-level-0);
	font-family: var(--page-font);
	font-size: var(--main-medium-big-font-size);
	position: relative;
	top: -14px;
}



DIV.agreement.vertical-line {
	border: solid 1px var(--lightBlue);
	min-height: 100vh;
	width: 0px;
	margin: 0px auto;
	opacity: 0.2;
	/*height: 1000px;*/
	/*position: absolute;*/
	/*left: 50%;*/
	/*top: 136px;*/
	/*z-index: 0;*/
	/*margin-bottom: 10rem;*/
}



/* for Firefox */
@-moz-keyframes shadowPulse {
	0% {
		box-shadow: 0px 0px 0px var(--lightBlue);
	}

	100% {
		box-shadow: 0px 0px 500px transparent;
	}
}

/* for Chrome */
@-webkit-keyframes shadowPulse {
	0% {
		box-shadow: 0px 0px 0px var(--lightBlue);
	}

	100% {
		box-shadow: 0px 0px 500px transparent;
	}
}

@keyframes shadowPulse {
	0% {
		box-shadow: 0px 0px 0px var(--lightBlue);
	}

	100% {
		box-shadow: 0px 0px 500px transparent;
	}
}






/* for Firefox */
@-moz-keyframes shadowGreenPulse {
	0% {
		box-shadow: 0px 0px 0px var(--color-outer-glow);
	}

	100% {
		box-shadow: 0px 0px 500px transparent;
	}
}

/* for Chrome */
@-webkit-keyframes shadowGreenPulse {
	0% {
		box-shadow: 0px 0px 0px var(--color-outer-glow);
	}

	100% {
		box-shadow: 0px 0px 500px transparent;
	}
}

@keyframes shadowGreenPulse {
	0% {
		box-shadow: 0px 0px 0px var(--color-outer-glow);
	}

	100% {
		box-shadow: 0px 0px 500px transparent;
	}
}



/* for Firefox */
@-moz-keyframes shadowRedPulse {
	0% {
		box-shadow: 0px 0px 0px var(--color-red-outer-glow);
		/*box-shadow: 0px 0px 0px var(--medium-red-color);*/
	}

	100% {
		box-shadow: 0px 0px 500px transparent;
	}
}

/* for Chrome */
@-webkit-keyframes shadowRedPulse {
	0% {
		box-shadow: 0px 0px 0px var(--color-red-outer-glow);
		/*box-shadow: 0px 0px 0px var(--medium-red-color);*/
	}

	100% {
		box-shadow: 0px 0px 500px transparent;
	}
}

@keyframes shadowRedPulse {
	0% {
		box-shadow: 0px 0px 0px var(--color-red-outer-glow);
		/*box-shadow: 0px 0px 0px var(--medium-red-color);*/
	}

	100% {
		box-shadow: 0px 0px 500px transparent;
	}
}


.area {
	margin-top: 10rem;
}


.sections {
	position: relative;
	top: 5vh;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	max-width: 95vw;
	justify-content: center;
	margin: 0px auto;
}

.section {
	width: calc(50% - 2rem);
	padding: 1rem;
	min-width: 360px;
	display: flex;
	flex-direction: column;
	max-width: 640px;
	align-items: center;
}

.section > LABEL {
	font-weight: 600;
	font-family: var(--page-font);
	font-size: var(--main-medium-big-font-size);
	display: block;
}

.section > IMG {
	width: calc(100% - 2rem);
	/*max-width: 460px;*/
	align-self: center;
	border-radius: 6px;
	overflow: hidden;
	margin: 1rem auto;
}


.window-frame {
	margin: 1rem;
	/*border: solid 1px var(--color-level-01);*/
	border-radius: 10px;
	padding: 0px;
	overflow: hidden;
	box-shadow: 0px 2px 10px var(--small-black-color);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.window-frame > DIV.title-bar {
	background: var(--color-secondary-main-2);
	/*background: var(--color-level-0);*/
	/*background: var(--color-anti-level-1);*/
	width: calc(100% - 1rem);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-content: center;
	align-items: center;
	padding: 3px 0.5rem 3px 0.75rem;

	font-family: var(--page-font);
	font-size: var(--main-font-size);
	color: var(--color-anti-level-4);
}

.window-frame > DIV.page {
	padding: 1rem;
	background: var(--color-level-01);
}

.window-frame > DIV.page > LABEL {
	font-weight: 500;
	display: block;
}

.window-frame > DIV.page > SPAN {
	font-family: var(--page-font);
	font-size: var(--main-font-size);
}

.window-frame > DIV.title-bar > DIV > LABEL {
	font-weight: 500;
	margin-right: 1rem;
}

.window-frame > DIV.title-bar > DIV > SPAN {
	line-height: 14px;
	max-height: 16px;
	display: inline-flex;
	align-self: center;
	position: relative;
	top: -1px;
	font-family: var(--main-font);
	font-size: var(--main-font-size);
	margin-left: 1rem;
	margin-right: 0.5rem;
}

.window-frame > DIV.title-bar > DIV > SPAN.tag {
	border: solid 1px var(--color-level-01);
	/*border: solid 1px var(--color-anti-level-4);*/
	border-radius: 100px;
	padding: 0px 8px;
	line-height: 14px;
	/*height: 20px;*/
	max-height: 16px;
	display: inline-flex;
	align-self: center;
	position: relative;
	top: -1px;
	font-family: var(--mono-font);
	font-size: var(--small-font-size);
	margin-left: 0.25rem;
	margin-right: 0.25rem;
}



.section > IMG.sizing {
	/*max-height: 100px;*/
	max-width: 200px;
	aspect-ratio: 1;
	height: auto;
}



.form-field-text-editable {
	background-color: var(--color-level-1);
	border-radius: 6px;
	height: 30px;
	padding-left: 20px;
	padding-right: 20px;
	margin-right: 10px;
	color: var(--lightBlue);
	border: solid 0px transparent;
	outline: none;
	width: 100%;
}
