#secure-section-component {
	display: flex;
	flex-direction: column;
	/*width: 720px;*/
	align-items: center;
	margin: 1rem auto;
	justify-content: flex-start;
	border-radius: 30px;
	overflow: hidden;
	font-family: var(--page-font);
	font-size: var(--main-font-section-size);
	max-height: 400px;
	/*height: 36vh;*/
	max-width: 1300px;
}

.secure-section-component-text-block {
	position: relative;
	top: 0px;
	left: 0px;
	text-align: justify;
	width: 100%;
	height: 100%;
	text-wrap: wrap;
	line-break: anywhere;
}

.ssctb-digest {
	background: var(--color-level-0);
	text-shadow: 0px 0px 8px var(--color-outer-glow);
	color: var(--color-inner-glow);

	/*filter: url(#glow);*/
}

.ssctb-clear {
	text-align: justify;
	opacity: 0.2;
	/*color: var(--color-anti-level-1);*/
}



#mask-circle {
	/*position: relative;*/
	/*top: -200%;*/
}

#mask-circle,
#maskInnerCircle,
#mask
{
	width: 100%;
	min-width: 400px;
	max-width: 1200px;

	height: 400px;
	min-height: 400px;
}


#mask {

}



#maskInnerCircle
	/*#anti-maskInnerCircle*/
{
	-moz-animation: maskScale 10s cubic-bezier(.25,.46,.45,.94) infinite;
	-webkit-animation: maskScale 10s cubic-bezier(.25,.46,.45,.94) infinite;
	animation: maskScale 10s cubic-bezier(.25,.46,.45,.94) infinite;
}




/* for Firefox */
@-moz-keyframes maskScale {
	from {
		r: 0px;
	}
	to {
		r: 5000px;
	}
}

/* for Chrome */
@-webkit-keyframes maskScale {
	from {
		r: 0px;
	}
	to {
		r: 5000px;
	}
}

@keyframes maskScale {
	from {
		r: 0px;
	}
	to {
		r: 5000px;
	}
}


.theme-dark {
	display: var(--display-theme-dark);
}

.theme-light {
	display: var(--display-theme-light);
}


.ssctb-image {
	position: relative;
	top: -80%;
	height: 80px;
	width: auto;
	aspect-ratio: 1;
}

.ssctb-label {
	position: relative;
	top: -80%;
	height: 25px;
	width: auto;
	aspect-ratio: 1;
	/*border: solid 2px var(--color-evident-1);*/
	border: solid 2px var(--color-inner-glow);
	padding-left: 1rem;
	border-radius: 50px;
	padding-right: 1rem;
	display: flex;
	flex-direction: row;
	align-self: center;
	align-items: center;
	color: var(--color-inner-glow);
	font-weight: 600;
	font-family: var(--mono-font);
	background: var(--middle-opacity-theme-color);
	cursor: pointer;
}
