.action-button {
    display: inline-flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    /*font-size: 1rem;*/
    /*background-color: #35f;*/
    /*background-color: #3388ff;*/
    /*color: #fff;*/
    background-color: var(--color-active-selection-2);
    color: var(--color-level-0);
    padding: 0.25rem;
    padding-left: 0.8rem;
    /*padding-right: 0.5rem;*/
    border-radius: 4px;
    margin: 10px;
    cursor: pointer;
    padding-right: 0.75rem;
    font-family: var(--main-font);
    font-size: 1rem;
    outline: none;

    text-align: center;
}


.action-button.disabled {
    background-color: var(--color-disabled-selection-1);
    font-style: italic;
    cursor: default;
}

.action-button-status {
    align-self: center;
    display: flex;
    margin-right: 0.25rem;
}




.error-label-text {
    color: var(--medium-red-color);
}

