

.status-in-wait {
    animation: arc-spin infinite 2s linear;
    alignSelf: center;
}

@keyframes arc-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


.red-glow {
    animation: one-time-red-pulse 2s linear;
}
@keyframes one-time-red-pulse {
    0% {
        box-shadow: 0px 0px 0px #f000;
        border-radius: 50px;
    }
    50% {
        box-shadow: 0px 0px 10px #f00;
        border-radius: 50px;
    }
    100% {
        box-shadow: 0px 0px 0px #f000;
        border-radius: 50px;
    }
}
.green-glow {
    animation: one-time-red-pulse 0.7s linear;
}
@keyframes one-time-green-pulse {
    0% {
        box-shadow: 0px 0px 0px #f000;
        border-radius: 50px;
    }
    50% {
        box-shadow: 0px 0px 20px #0f0;
        border-radius: 50px;
    }
    100% {
        box-shadow: 0px 0px 0px #f000;
        border-radius: 50px;
    }
}
